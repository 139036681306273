import type { PropsWithChildren } from 'react'
import dynamic from 'next/dynamic'
import NextLink from 'next/link'
import { useRouter } from 'next/router'
import { useTranslation } from 'next-i18next'

import type { FooterProps } from '@knauf-group/ct-designs/components/core/Footer/Footer.types'
import type { MiniHeader } from '@knauf-group/ct-designs/components/core/MiniHeader/MiniHeader'
import type { IAppsConfig } from '@knauf-group/ct-shared-nextjs/client'
import { WebFooter } from '@knauf-group/ct-shared-nextjs/components/WebFooter/WebFooter'
import logger from '@knauf-group/ct-shared-nextjs/logger'
import type { SharedContentfulProps } from '@knauf-group/ct-shared-nextjs/web'
import { useClickEvent } from '@knauf-group/ct-shared-nextjs/web/hooks'
import { Stack, Toolbar } from '@mui/material'

import type ScrollTop from '@/components/ScrollTop'
import { WebHeader2 } from '@/components/WebHeader2'
import { useLocalePathGenerator } from '@/utils/frontend/usePathGenerator'
import { getPageType } from '@/utils/plain/getPageType'

type WithMiniHeader = {
  hasMiniHeader: true
  appsConfig?: never
}

type WithoutMiniHeader = {
  hasMiniHeader?: false
  appsConfig: IAppsConfig
}

type PageLayoutProps = PropsWithChildren<
  SharedContentfulProps & (WithMiniHeader | WithoutMiniHeader)
>

const DynamicScrollTop = dynamic<React.ComponentProps<typeof ScrollTop>>(
  () => import('@/components/ScrollTop'),
  {
    ssr: false,
  },
)

const DynamicMiniHeader = dynamic<React.ComponentProps<typeof MiniHeader>>(
  () => import('@knauf-group/ct-designs/components/core/MiniHeader/MiniHeader'),
)

const fallbackFooterEntries: FooterProps = { socialLinks: [], copyright: '' }

export const PageLayout = (props: PageLayoutProps) => {
  const { children, hasMiniHeader, appsConfig } = props
  const router = useRouter()
  const { getHomePath } = useLocalePathGenerator()
  const { push: pushClickEvent } = useClickEvent()
  const { t: tSharedNextjs } = useTranslation('shared-nextjs')

  const pageSlug = router.asPath.split('/')[2]

  const pageType = getPageType(pageSlug, router.locale)

  const analyticsPageName = pageType === 'category' ? 'product_catalog' : 'product_detail_page'

  // fallback header and footer entries, they can we null, that's why we use ?? and not a default value
  // eslint-disable-next-line react/destructuring-assignment
  const headerEntries = props.headerEntries ?? []
  // eslint-disable-next-line react/destructuring-assignment
  const footerEntries = props.footerEntries ?? fallbackFooterEntries

  const { t } = useTranslation('cms', { keyPrefix: 'common' })

  const goHome = () => {
    const homePath = getHomePath()

    pushClickEvent?.({
      reference: { label: 'logo', href: homePath, external: false },
      type: 'link',
      eventPayload: {
        page_name: analyticsPageName,
        target: homePath,
        page_section: 'header',
      },
    })

    router.push(homePath)
  }

  const toggleCookiesBanner = () => {
    try {
      // @ts-expect-error we don't know OneTrust type, but we know it has ToggleInfoDisplay
      window.OneTrust.ToggleInfoDisplay()
    } catch (error: any) {
      // most of the time the error happens because the browser blocks all the cookies
      logger.error(error?.message)
    }
  }

  return (
    <Stack sx={{ minHeight: '100vh' }}>
      {hasMiniHeader ? (
        <DynamicMiniHeader onLogoClick={goHome} />
      ) : (
        <WebHeader2
          analytics={{ eventPayload: { page_name: analyticsPageName } }}
          appsConfig={appsConfig}
          navigationLinks={headerEntries}
          onClickLogo={goHome}
          // @ts-expect-error type mismatch
          closeMobileDrawerA11yLabel={tSharedNextjs('drawer.close-drawer')}
        />
      )}

      <Toolbar />
      {children}
      <DynamicScrollTop />

      <WebFooter
        {...footerEntries}
        languageText={t('languageSelector')}
        // @ts-expect-error TODO: type mismatch after upgrading to latest next
        nextLinkComponent={NextLink}
        cookieSetting={{
          onClick: toggleCookiesBanner,
          cookieSettingLabel: t('cookies.cookiesSettings'),
        }}
        analytics={{ eventPayload: { page_name: analyticsPageName } }}
      />
    </Stack>
  )
}
