import { useMemo } from 'react'
import { useRouter } from 'next/router'

import { getCategoryPagePath, getHomePath, getProductDetailsPath } from '../plain/PathGenerator'

export function useLocalePathGenerator() {
  const { locale } = useRouter()

  return useMemo(
    () => ({
      getHomePath: () => getHomePath({ locale }),
      getCategoryPagePath: (props: { categorySlug?: string; queryParams?: string } = {}) =>
        getCategoryPagePath({ ...props, locale }),
      getProductDetailsPath: (productSlug: string) =>
        getProductDetailsPath({ locale, productSlug }),
    }),
    [locale],
  )
}
